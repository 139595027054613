import React from "react"
import AlbumText from "../../components/AlbumText"
import Layout from "../../components/Layout"
import People from "../../components/People"
import Tracks from "../../components/Tracks"
import colors from "../../colors"
import { getAlbum } from "../../data/albums"
import AlbumCover from "../../components/AlbumCover"
import Title from "../../components/Title"

const album = getAlbum("essential-trips")

export default () => (
  <Layout backgroundColor={colors.white} textColor={colors.black}>
    <AlbumCover filename="essential-trips.png" borderColor={colors.black} />
    <Title color={colors.black}>The Wig Out: Essential Trips</Title>
    <p>
      Release Date: <strong>July 7th, 2020</strong>
    </p>
    <AlbumText>
      <p>
        <strong>Essential Trips</strong> is the fourth album from The Wig Out
        (see also:{" "}
        <a href="/press/baked-dwarves">Baked Dwarves Moan At Harvestman</a>,{" "}
        <a href="/press/number-two">Number Two</a>, and
        <a href="/press/we-have-a-problem">We Have A Problem</a>
        ).
      </p>
      <p>
        Essential Trips sees most of the lineup from Number Two return, and is
        the most wide-raning release yet from The Wig Out. This journey starts
        with an ode to chicken nugget dipping sauces, before moving on through
        kraut pop, capitalist critique, goth surrealism, a hard rock ballad of
        lost youth, and more.
      </p>
      <p>
        Adapting creative constraint practices like exquisite corpse and
        conditional drawing to our pandemic-imposed isolation, The Wig Out
        follows a unique process. Each release is recorded and mixed in a period
        of four weeks, with each track passing to a different musician to add,
        substract and mutilate as they see fit.
      </p>
    </AlbumText>
    <Title color={colors.black}>Tracks</Title>
    <Tracks tracks={album.tracks} />
    <Title color={colors.black}>People</Title>
    <AlbumText>Recorded and mixed in June and July 2020 by:</AlbumText>
    <People album={album} />
  </Layout>
)
